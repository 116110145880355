export const initMixin = {
    data() {
        return {
            inited: false
        };
    },
    async fetch() {
        await this.getData();
    },
    async mounted () {
        if (this.$auth.getToken()) {
            await this.getUser();
        }else{
            this.inited = true
            this.$store.commit('setLoaded', 'user');
        }
    },
    methods: {
        getUser: function () {
            return this.$axios.get('/me')
              .then((res) =>{
                  this.$store.commit('setUser', res.user);
                  this.$store.commit('setLoaded', 'user');
                  this.$store.commit('setActiveBookingsCount', res.active_bookings_count);
                  this.$auth.setRole('client');
                  this.$acl.change('client')
                  this.inited = true
              })
              .catch(() =>{
                  this.$auth.setRole('public')
                  this.$acl.change('public')
                  this.inited = true;
              })
        },
        getData: function () {
            return this.$axios.get('/get-data')
              .then((res) => {
                  this.$store.commit('setServiceTypes', res.service_types)
                  this.$store.commit('setLoaded', 'serviceTypes');
              })
        },
        async getLocation () {
            await navigator.geolocation.getCurrentPosition(
              position => {
                  this.$store.commit('setPosition', {
                      latitude: position.coords.latitude,
                      longitude: position.coords.longitude
                  })
              },
              error => {
                  console.log(error.message)
              },
            )
        },
    }
}

