export default () => {
    return {
        "home": {
            "header-title": "Discover the best places!",
            "watch-video": "Watch video",
            "featured": "Featured",
            "featured_categories": "Featured<br> Categories",
            "top_rated_cities": "Top rated cities",
            "top_destinations": "Our top <br> Destinations",
            "top_destinations_description": "",
            "best_places": "Best places",
            "top_salons": "Top Salons",
            "application": "Application",
            "download_app_title": "Download the app to<br> book wherever you are!",
            "download_app_description": ""
        },
        "subscribe": {
            "subscribe": "Subscribe",
            "title": "Subscribe to receive our newsletter",
            "description": ""
        },
        "login_page": {
            "discover_services_title": "Discover the best services",
            "discover_services_subtitle": "Explore top-rated salons and services, make an appointment and more",
            "no_account": "Don't have an account?",
            "cancel_reset": "Cancel reset",
            "already_have_account": "Already have an account",
            "month_free": "1st Month Free",
            "accept_terms": "By signing up you accept our",
            "terms_and_conditions": "Terms & Conditions",
            "or_continue_with": "Or continue with"
        },

        "enter_email": "Enter your email address",
        "confirm_email": "Confirm email address",
        "categories": "Categories",
        "service_type": "Service Type",
        "enter_service_type": "Enter service type",
        "enter_service": "Enter service",
        "service_category": "Service Category",
        "enter_service_category": "Enter service category",
        "enter_location": "Enter Location",
        "see_more": "See more",

        "logout": "Logout",
        "edit_account": "Edit account",
        "login": "Log in",
        "signin": "Sign in",
        "signup": "Sign up",
        "add_photo": "Add photo",
        "edit_photo": "Edit photo",

        "personal_data": "Personal Data",
        "first_name": "First Name",
        "last_name": "Last Name",
        "e-mail": "E-mail",
        "phone_number": "Phone Number",
        "phone": "Phone",
        "address": "Address",
        "address_hint": "We use your address to show you the nearest services",
        "property_no": "Property no.",
        "street": "Street",
        "city": "City",
        "zip_code": "Postal Code",

        "password": "Password",
        "change_password": "Change password",
        "current_password": "Current password",
        "new_password": "New password",
        "confirm_password": "Confirm password",
        "forgot_password": "Forgot password",
        "forgot_password_hint": "Enter your email and you will receive an email to reset your password",
        "resend_confirmation_email": "Resend confirmation link",
        "reset": "Reset",
        "reset_password": "Reset password",
        "re_type_password": "Re-Type Password",
        "administrator_password": "Administrator password",
        "password_hint": "Password must be six or more characters long, must contain uppercase letter, lowercase letter, number and special character",

        "change_email": "Change e-mail",
        "new_email": "New e-mail",
        "email": "E-mail",

        "booking_history": "Booking History",
        "future_bookings": "Your Future Bookings",
        "no_future_bookings": "No Future Bookings",
        "past_bookings": "Past Bookings",
        "no_past_bookings": "No Past Bookings",
        "booking_details": "Booking Details",
        "bookings": "Bookings",
        "booking": "Booking",
        "new_booking": "New booking",
        "edit_booking": "Edit booking",

        "location": "Location",
        "date": "Date",
        "price": "Price",
        "service": "Service",
        "duration_of_service": "Duration of service",
        "employee": "Employee",
        "employees": "Employees",
        "book_again": "Book again",

        "my_favorites": "My Favorites",

        "my_reviews": "My Reviews",
        "add_review": "Add review",
        "review_hint": "",
        "submit_review": "Submit Review",
        "rating": "Rating",
        "hide_name_photo": "Hide your name & photo. Write a review anonymously.",

        "no_sales": "No sales",

        "no_reviews": "No reviews",
        "you_have_no_reviews": "You have no reviews",

        "payment_history": "Payment History",
        "payment_summary": "Payment Summary",
        "no_payment": "No payments",
        "amount": "Amount",
        "service_name": "Service Name",
        "payment_method": "Payment Method",
        "service_fee": "Service Fee",
        "discount": "Discount",
        "total_amount": "Total Amount",

        "notifications": "Notifications",
        "turn_on_off_notifications": "Turn on/off all notifications",

        "subscription": "Subscription",

        "filters": "Filters",
        "choose_city": "Choose City",
        "distance": "Distance",
        "range_of_services": "Range Of Services",
        "availability": "Availability",
        "any": "Any",
        "monday": "Monday",
        "tuesday": "Tuesday",
        "wednesday": "Wednesday",
        "thursday": "Thursday",
        "friday": "Friday",
        "saturday": "Saturday",
        "sunday": "Sunday",
        "price_range": "Price Range",
        "apply": "Apply",
        "total": "Total",
        "results_found": "Results found",
        "default": "Default",

        "special_offers": "Special Offers",
        "profesionals_near_you": "Professionals Near You",

        "search": {
            "no_data": "No Data",
            "search": "Search",
            "placeholder": "Service / Salon",
            "not_found": "Not found",
            "city_not_found": "City not found",
            "city": "Search City",
            "city_by_name": "Search city by name",
            "country": "Search Country",
            "country_not_found": "Country not found",
            "select_country": "Select country",
            "county": "Search province",
            "county_not_found": "Province not found",
            "select_county": "Select province",
            "employee_not_found": "Employee not found",
            "select_employee": "Select employee",
            "customer_not_found": "Customer not found",
            "select_customer": "Select customer",
            "business_not_found": "Business not found",
            "select_business": "Select business",
            "service_not_found": "Service not found"
        },

        "leave_feedback": "Please leave us feedback",
        "add_feedback": "Please leave your feedback regarding booking process. This can help us improve",
        "send_feedback": "Send feedback",
        "feedback_sent": "Feedback sent",
        "congratulation": "Congrats",
        "payment_complete": "Payment complete",
        "booking_hint": "Your booking is confirmed.",
        "book_now": "Book Now",
        "add_to_calendar": "Add to calendar",
        "reminder_24_email": "Send me a 24 hour reminder through e-mail",
        "reminder_24_sms": "Send me a 24 hour reminder through text",
        "auto_notify": "We will automatically send you a notification 24 hour through the app prior to your appointment",
        "back_home": "Home Page",
        "service_details": "Service Details",
        "payment": "Payment",
        "confirmation": "Confirmation",
        "booking_code": "Booking code",
        "summary": "Summary",
        "confirmed": "Confirmed",
        "any_employee": "Any Employee",
        "change_employee": "Change Employee",
        "change_service": "Change Service",
        "your_information": "Your information",
        "name": "Name",
        "view_here": "view here",
        "privacy_policy": "Privacy Policy",
        "cookies_policy": "Cookies Policy",
        "contact_us": "Contact us",
        "contact_hint": "How can we help?",
        "get_back_hint": "We'll get back to you shortly!",
        "message": "Message",
        "i_agree_privacy_policy": "I Agree to Privacy Policy",
        "selected_services": "Selected Services",
        "use_apple_pay": "Use Apple Pay",
        "use_paypal": "Use PayPal",
        "use_credit_card": "Use Credit Card",
        "new_credit_card": "New Credit Card",
        "save_new_card": "Save Credit Card",
        "promo_code": "Promo code",
        "enter_promo_code": "Enter Your Promo code",
        "pay_now": "Pay Now",
        "pay_now_value": "Pay Now {value}",
        "pay_in_house": "Pay In House",
        "choose": "Choose",
        "cards": "Cards",
        "payment_cards": "Payment cards",
        "no_cards_added": "No Cards Added",
        "add_credit_card": "Add credit card",
        "credit_card": "Credit Card",
        "enter_card_details": "enter your card details",
        "credit_card_number": "Credit card number",
        "month": "Month",
        "year": "Year",
        "enter_name": "Enter your name",

        "our_mission": "Our Mission",
        "our_mission_description": "",

        "leave_a_review": "Leave A Review",
        "about_us": "About Us",
        "what_we_offer": "What We Offer",
        "services": "Services",
        "reviews": "Reviews",
        "our_team": "Our Team",
        "contact": "Contact",
        "call": "Call",
        "operation_hours": "Hours of Operation",
        "general_hours": "General Hours",
        "total_services": "Total services",
        "opened_now": "Opened now",
        "closed": "Closed",
        "back": "Back",
        "send": "Send",
        "cancel": "Cancel",
        "save": "Save",
        "accept": "Accept",
        "status": "Status",
        "duration": "Duration",
        "client": "Client",
        "client_name": "Client name",
        "add": "Add",
        "remove": "Remove",
        "remove_hint": "Are you sure you want to remove this place from your favourite list?",
        "logo": "Logo",
        "edit": "Edit",
        "delete": "Delete",
        "delete_profile": "Delete profile",
        "yes": "Yes",
        "no": "No",
        "refresh": "Refresh",

        "add_appointment": "Add reservation",
        "all_types": "All Types",
        "appointments": "Appointments",
        "hour": "Hour",

        "january": "January",
        "february": "February",
        "march": "March",
        "april": "April",
        "may": "May",
        "june": "June",
        "july": "July",
        "august": "August",
        "september": "September",
        "october": "October",
        "november": "November",
        "december": "December",

        "mon": "mon",
        "tue": "tue",
        "wed": "wed",
        "thu": "thu",
        "fri": "fri",
        "sat": "sat",
        "sun": "sun",
        "days_of_week": {
            "1": "Monday",
            "2": "Tuesday",
            "3": "Wednesday",
            "4": "Thursday",
            "5": "Friday",
            "6": "Saturday",
            "7": "Sunday"
        },

        "business_profile": "Business Profile",
        "business_owner_personal_data": "Business Owner's Personal Data",
        "business_name": "Business Name",
        "business_description": "Business description",
        "public_profile": "Setup profile as public",
        "switch_professional": "Switch to professional",
        "switch_business": "Switch to business profile",

        "sales": "Sales",
        "statistics_reports": "Statistics & reports",
        "privacy": "Privacy",
        "settings": "Settings",

        "add_photos": "Add photos",
        "error": "Error",
        "image_details": "Image details",

        "employee_name": "Employee Name",
        "profession": "Profession",
        "years_of_experience": "Years of experience",
        "available_on": "Available on",
        "no_employees": "No employees",
        "add_new_employee": "Add New Employee",
        "add_service": "Add service",
        "your_services": "Your services",
        "edit_service": "Edit service",
        "edit_services": "Edit services",
        "for_businesses": "For Businesses",
        "assign": "Assign",
        "assign_services": "Assign Services",
        "business_information": "Business Information",
        "assigned_services": "Assigned Services",
        "pick_top_services": "Pick your top 3 services to be visible on the business preview for the customers",
        "top_service": "Top service",

        "rights_reserverd": "All rights reserved",

        "layout": {
            "home": "Home",
            "explore": "Explore",
            "appointments": "Appointments",
            "notifications": "Notifications",
            "favorites": "Favorites",
            "user": "User"
        },
        "see_all_from": "See all from {from}",
        "clear_filters": "Clear filters",
        "sort_by": "Sort by",
        "featured": "Featured",
        "sort_a_z": "Sort from A to Z",
        "sort_z_a": "Sort from Z to A",
        "show_results": "Show Results!",
        "listing_view": "Listing View",
        "map_view": "Map View",
        "price_quality": "Price/quality",
        "staff": "Staff",
        "popular_in_your_area": "Popular In Your Area",
        "pick_team_member": "Pick Team Member",
        "selected_professional": "Selected Professional",
        "random": "Random",
        "all": "All",

        "languages": "Languages",
        "hello": "Hello",
        "esse_business_account": "Esse Business Account",
        "step": "Step",
        "basic_information": "Basic information",
        "no_service_selected": "No service selected",
        "add_one_service": "Add at least one service to allow customer booking",
        "next_step": "Next step",
        "previous_step": "Previous step",
        "get_started": "Let's get started!",
        "no_professionals_near_you": "No Professionals Near You",
        "setup_as_professional_account": "Setup account as a professional",

        "are_you_sure": "Are you sure?",
        "alert_cancel_booking": "Are you sure you want to cancel the booking?",
        "alert_reschedule_event": "Are you sure you want to reschedule appointment #",
        "alert_change_booking_duration": "Are you sure you want to change the duration of the appointment #",
        "alert_are_you_sure": "Are you sure?",
        "alert_logout": "Are you sure you want to log out?",
        "alert_delete_service": "Are you sure you want to delete the service?",
        "alert_delete": "Are you sure you want to delete",
        "alert_generate_password": "Are you sure you want to generate a new password?",
        "alert_password_reset_email": "Are you sure you want to send the password reset email?",
        "alert_block_account": "Are you sure you want to block this account?",
        "alert_activate_account": "Are you sure you want to activate this account?",
        "alert_delete_account": "Are you sure you want to delete the account?",
        "alert_move_message": "Are you sure you want to move this message?",
        "alert_move_to_archive": "Are you sure you want to move this message to the archive?",
        "alert_delete_image": "Are you sure you want to delete the image?",
        "alert_change_to_professional": "Are you sure you want to switch to professional profile? This will limit some functionalities, delete employees",
        "alert_change_to_business": "Are you sure you want to switch to business profile?",

        "billing": "Billing",
        "schedule": "Schedule",
        "break": "Break",
        "no_favorites": "No Favorites",
        "add_break": "Add Break",
        "edit_schedule": "Edit Schedule",
        "no_time_slots": "Sorry, there are not available slots on the selected date, please select another day",
        "timezone_disclaimer": "Please be aware that the time slots are based on the business's current time zone",

        "dashboard": "Dashboard",
        "reset_filters": "Reset filters"
    }
}
