export default () => {
    return {
        "home": {
            "header-title": "Découvrez les meilleurs endroits!",
            "watch-video": "Regarder la vidéo",
            "featured": "En vedette",
            "featured_categories": "En vedette<br> Catégories",
            "top_rated_cities": "Villes les mieux notées",
            "top_destinations": "Nos meilleures destinations <br>",
            "top_destinations_description": "En quelques clics seulement, vous pouvez réserver votre prochain rendez-vous avec n'importe quel fournisseur ESSE participant partout dans le monde. <br><br>Que votre rendez-vous soit lors d'un voyage d'affaires, de vacances en famille ou même d'un week-end, ESSE prend en charge vos réservations potentielles dans le monde entier. <br><br>En toute simplicité, ESSE vous connecte à tous les fournisseurs locaux où que vous décidiez de rester. ESSE est là pour vous aider à trouver le bon service, au bon moment à la destination de votre choix.",
            "best_places": "Meilleurs endroits",
            "top_salons": "Meilleurs salons",
            "application": "L'application" ,
            "download_app_title": "Téléchargez l'application pour<br> réserver où que vous soyez !",
            "download_app_description": "Téléchargez notre application Web progressive et réservez votre prochain rendez-vous par téléphone!"
        },
        "subscribe": {
            "subscribe": "S'abonner",
            "title": "Abonnez-vous pour recevoir notre newsletter",
            "description": "><<><<<><><><><<NO DESCRIPTION YET><><><><><><><><>"
        },
        "login_page": {
            "discover_services_title": "Découvrez les meilleurs services",
            "discover_services_subtitle": "Explorez les salons et services les mieux notés, prenez rendez-vous et plus encore",
            "no_account": "Vous n'avez pas de compte?",
            "cancel_reset": "Annuler réinitialisation",
            "already_have_account": "Vous avez déjà un compte",
            "month_free": "1er mois gratuit",
            "accept_terms": "En vous inscrivant, vous acceptez notre",
            "terms_and_conditions": "Termes et Conditions",
            "or_continue_with": "Ou continuer avec"
        },

        "enter_email": "Entrez votre adresse email",
        "categories": "Catégories",
        "service_type": "Type De Service",
        "enter_service_type": "Entrez le type de service",
        "enter_service": "Entrez Le Service",
        "service_category": "Catégorie de Services",
        "enter_service_category": "Entrer La Catégorie De Service",
        "enter_location": "Entrez l'emplacement",
        "see_more": "Voir plus",
        "logout": "Se Déconnecter",
        "edit_account": "Modifier le compte",
        "login": "Connexion",
        "signin": "S'identifier",
        "signup": "S'inscrire",
        "add_photo": "Ajouter une photo",
        "edit_photo": "Modifier photo",

        "personal_data": "Données personnelles",
        "first_name": "Prénom",
        "last_name": "Nom de famille",
        "e-mail": "E-mail",
        "phone_number": "Numéro de téléphone",
        "phone": "téléphone",
        "address": "adresse",
        "address_hint": "Nous utilisons votre adresse pour vous montrer les services les plus proches",
        "property_no": "Propriété non.",
        "street": "Rue",
        "city": "Ville",
        "zip_code": "Code postal",

        "password": "Mot de passe",
        "change_password": "Changer le mot de passe",
        "current_password": "Mot de passe actuel",
        "new_password": "Nouveau mot de passe",
        "confirm_password": "Confirmez le mot de passe",
        "forgot_password": "Mot de passe oublié",
        "forgot_password_hint": "Entrez votre email et vous recevrez un email pour réinitialiser votre mot de passe",
        "resend_confirmation_email" : "Renvoyer le lien de confirmation",
        "reset": "Réinitialiser",
        "reset_password": "Réinitialiser le mot de passe",
        "re_type_password": "Retaper le mot de passe",
        "administrator_password": "Mot de passe administrateur",
        "password_hint": "Le mot de passe doit comporter six caractères ou plus, doit contenir une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial",

        "change_email": "Changer l'e-mail",
        "new_email": "Nouveau courriel",
        "email": "E-mail",

        "booking_history": "Historique des réservations",
        "future_bookings": "Vos futures réservations",
        "no_future_bookings": "Aucune future réservation",
        "past_bookings": "Réservations passées",
        "no_past_bookings": "Aucune réservation passée",
        "booking_details": "Les détails de réservation",
        "bookings": "Réservations",
        "booking": "Réservation",
        "new_booking": "Nouvelle réservation",
        "edit_booking": "Modifier la réservation",

        "location": "Emplacement",
        "date": "Date",
        "price": "Prix",
        "service": "Service",
        "duration_of_service": "Durée du service",
        "employee": "Employée",
        "employees": "des employés",
        "book_again": "Réservez à nouveau",

        "my_favorites": "Mes favoris",

        "my_reviews": "Mes avis",
        "add_review": "Ajouter un commentaire",
        "review_hint": "",
        "submit_review": "Poster le commentaire",
        "rating": "Évaluation",
        "hide_name_photo": "Cachez votre nom et votre photo. Donnez votre avis de manière anonyme.",

        "no_sales": "Pas de ventes",

        "no_reviews": "Aucun avis",
        "you_have_no_reviews": "Vous n'avez pas d'avis",

        "payment_history": "Historique de paiement",
        "payment_summary": "Résumé de paiement",
        "no_payment": "Aucun paiement",
        "amount": "Somme",
        "service_name": "Nom du service",
        "payment_method": "Mode de paiement",
        "service_fee": "Frais de service",
        "discount": "Remise",
        "total_amount": "Montant total",

        "notifications": "Notifications",
        "turn_on_off_notifications": "Activer/désactiver toutes les notifications",

        "subscription": "Abonnement",

        "filters": "Filtres",
        "choose_city": "Choisissez la ville",
        "distance": "Distance",
        "range_of_services": "Ensemble de services",
        "availability": "Disponibilité",
        "any": "N'importe quel",
        "monday": "Lundi",
        "tuesday": "Mardi",
        "wednesday": "Mercredi",
        "thursday": "Jeudi",
        "friday": "Vendredi",
        "saturday": "Samedi",
        "sunday": "Dimanche",
        "price_range": "Échelle des prix",
        "apply": "Appliquer",
        "total": "Total",
        "results_found": "Résultats trouvés",
        "default": "Défaut",

        "special_offers": "Offres spéciales",
        "profesionals_near_you": "Des professionnels près de chez vous",

        "search": {
            "no_data": "Pas de données",
            "search": "Chercher",
            "placeholder": "Services/Salon",
            "not_found": "Pas trouvé",
            "city_not_found": "Ville introuvable",
            "city": "Rechercher une ville",
            "city_by_name": "Rechercher une ville par nom",
            "country": "Rechercher un pays",
            "country_not_found": "Pays introuvable",
            "select_country": "Choisissez le pays",
            "employee_not_found": "Employé introuvable",
            "select_employee": "Sélectionnez l'employé",
            "customer_not_found": "Client introuvable",
            "select_customer": "Sélectionnez le client",
            "business_not_found": "Entreprise introuvable",
            "select_business": "Sélectionnez une entreprise",
            "service_not_found": "Service non trouvé"
        },

        "leave_feedback": "S'il vous plaît laissez-nous vos commentaires",
        "add_feedback": "Veuillez laisser vos commentaires concernant le processus de réservation. Cela peut nous aider à améliorer",
        "send_feedback": "Envoyer des commentaires",
        "feedback_sent": "Commentaires envoyés",
        "congratulation": "Félicitations",
        "payment_complete": "Paiement terminé",
        "booking_hint": "Votre réservation est confirmée.",
        "book_now": "Reserve maintenant",
        "add_to_calendar": "Ajouter au calendrier",
        "reminder_24_email": "Envoyez-moi un rappel de 24 heures par e-mail",
        "reminder_24_sms": "Envoyez-moi un rappel 24 heures par SMS",
        "auto_notify": "Nous vous enverrons automatiquement une notification 24 heures via l'application avant votre rendez-vous",
        "back_home": "Page d'accueil",
        "service_details": "Détails des services",
        "payment": "Paiement",
        "confirmation": "Confirmation",
        "booking_code": "Numéro de réservation",
        "summary": "Sommaire",
        "confirmed": "Confirmé",
        "any_employee": "Tout employé",
        "change_employee": "Changer d'employé",
        "change_service": "Changer de service",
        "your_information": "Vos informations",
        "name": "Nom",
        "view_here": "voir ici",
        "privacy_policy": "Politique de confidentialité",
        "cookies_policy": "Politique de cookies",
        "contact_us": "Contactez-nous",
        "contact_hint": "Comment pouvons nous aider?",
        "get_back_hint": "Nous vous répondrons sous peu!",
        "message": "Message",
        "i_agree_privacy_policy": "J'accepte la politique de confidentialité",
        "selected_services": "Prestations sélectionnées",
        "use_apple_pay": "Utiliser ApplePay",
        "use_paypal": "Utiliser PayPal",
        "use_credit_card": "Utiliser une carte de crédit",
        "new_credit_card": "Nouvelle carte de crédit",
        "save_new_card": "Enregistrer la carte de crédit",
        "promo_code": "Code promo",
        "enter_promo_code": "Entrez votre code promo",
        "pay_now": "Payez maintenant",
        "pay_now_value": "Payer maintenant {value}",
        "pay_in_house": "Payer sur place",
        "choose": "Choisir",
        "cards": "Cartes",
        "payment_cards": "Cartes de paiement",
        "no_cards_added": "Aucune carte ajoutée",
        "add_credit_card": "Ajouter une carte de crédit",
        "credit_card": "Carte de crédit",
        "enter_card_details": "entrez les détails de votre carte",
        "credit_card_number": "Numéro de Carte de Crédit",
        "month": "Mois",
        "year": "An",
        "enter_name": "Entrez votre nom",

        "our_mission": "",
        "our_mission_description": ".",

        "leave_a_review": "Laisser un commentaire",
        "about_us": "À propos de nous",
        "what_we_offer": "Ce que nous offrons",
        "services": "Prestations de service",
        "reviews": "Commentaires",
        "our_team": "Notre équipe",
        "contact": "Contact",
        "call": "Appel",
        "operation_hours": "Heures d'ouverture",
        "general_hours": "Heures générales",
        "total_services": "Services totaux",
        "opened_now": "Ouvert maintenant",
        "back": "Retour",
        "send": "Envoyer",
        "cancel": "Annuler",
        "save": "sauvegarder",
        "accept": "Accepter",
        "status": "Statut",
        "duration": "Durée",
        "client": "Client",
        "client_name": "Nom du client",
        "add": "Ajouter",
        "remove": "Retirer",
        "remove_hint": "Voulez-vous vraiment supprimer ce lieu de votre liste de favoris?",
        "logo": "Logo",
        "edit": "Éditer",
        "delete": "Effacer",
        "delete_profile": "Supprimer le profil",
        "yes": "Oui",
        "no": "Non",
        "refresh": "Rafraîchir",

        "add_appointment": "Ajouter une réservation",
        "all_types": "Tous les types",
        "appointments": "Rendez-vous",
        "hour": "Heure",

        "january": "Janvier",
        "february": "Février",
        "march": "Mars",
        "april": "Avril",
        "may": "Peut",
        "june": "Juin",
        "july": "Juillet",
        "august": "Août",
        "september": "Septembre",
        "october": "Octobre",
        "november": "Novembre",
        "december": "Décembre",

        "mon": "Lun",
        "tue": "Mar",
        "wed": "Mer",
        "thu": "Jeu",
        "fri": "Ven",
        "sat": "Sam",
        "sun": "Dim",
        "days_of_week": {
          "1": "Lundi",
          "2": "Mardi",
          "3": "Mercredi",
          "4": "Jeudi",
          "5": "Vendredi",
          "6": "Samedi",
          "7": "Dimanche"
    },

    "business_profile": "Profil de l'entreprise",
      "business_owner_personal_data": "Données personnelles du propriétaire de l'entreprise",
      "business_name": "Nom de l'entreprise",
      "business_description": "Description de l'activité",
      "public_profile": "Configurer le profil comme public",
      "switch_professional": "Passer au professionnel",
      "switch_business": "Passer au profil d'entreprise",

      "sales": "Ventes",
      "statistics_reports": "Statistiques et rapports",
      "privacy": "Intimité",
      "settings": "Réglages",

      "add_photos": "Ajouter des photos",
      "error": "Erreur",
      "image_details": "Détails de l'image",

      "employee_name": "Nom de l'employé",
      "profession": "Profession",
      "years_of_experience": "Des années d'expérience",
      "available_on": "Disponible sur",
      "no_employees": "Aucun employé",
      "add_new_employee": "Ajouter un nouvel employé",
      "add_service": "Ajouter un service",
      "your_services": "Vos prestations",
      "edit_service": "Modifier le service",
      "edit_services": "Modifier les services",
      "for_businesses": "Pour les entreprises",
      "assign": "Attribuer",
      "assign_services": "Attribuer des services",
      "business_information": "Informations d'affaires",
      "assigned_services": "Services assignés",
      "pick_top_services": "Choisissez vos 3 meilleurs services pour être visibles sur l'aperçu de l'entreprise pour les clients",
      "top_service": "Service de qualité",

      "rights_reserverd": "Tous les droits sont réservés",

      "layout": {
        "home": "Page d'accueil",
          "explore": "Explorer",
          "appointments": "Rendez-vous",
          "notifications": "Avis",
          "favorites": "Favoris",
          "user": "Utilisateur"
    },
    "see_all_from": "Voir tout de {de",
      "clear_filters": "Effacer les filtres",
      "sort_by": "Trier par",
      "featured": "Mis en exergue",
      "sort_a_z": "Trier de A à Z",
      "sort_z_a": "Trier de Z à A",
      "show_results": "Montrer les résultats!",
      "listing_view": "Affichage de la liste",
      "map_view": "Vue de la carte",
      "price_quality": "Prix/qualité",
      "staff": "Personnel",
      "popular_in_your_area": "Populaire dans votre région",
      "pick_team_member": "Choisissez un membre de l'équipe",
      "selected_professional": "Professionnel sélectionné",
      "random": "Aléatoire",
      "all": "Tout",

      "languages": "Langages",
      "hello": "Bonjour",
      "esse_business_account": "Esse Compte d'entreprise",
      "step": "étape",
      "basic_information": "Informations de base",
      "no_service_selected": "Aucun service sélectionné",
      "add_one_service": "Ajoutez au moins un service pour permettre aux clients de réserver",
      "next_step": "L'étape suivante",
      "previous_step": "Étape précédente",
      "get_started": "Commençons!",
      "no_professionals_near_you": "Aucun professionnel près de chez vous",
      "setup_as_professional_account": "Créer un compte en tant que professionnel",

      "are_you_sure": "Êtes-vous sûr?",
      "alert_cancel_booking": "Voulez-vous vraiment annuler la réservation ?",
      "alert_reschedule_event": "Êtes-vous sûr de vouloir reporter le rendez-vous #",
      "alert_change_booking_duration": "Êtes-vous sûr de vouloir modifier la durée du rendez-vous #",
      "alert_are_you_sure": "Êtes-vous sûr?",
      "alert_logout": "Êtes-vous sûr de vouloir vous déconnecter?",
      "alert_delete_service": "Voulez-vous vraiment supprimer le service ?",
      "alert_delete": "Etes-vous sûr que vous voulez supprimer",
      "alert_generate_password": "Voulez-vous vraiment générer un nouveau mot de passe ?",
      "alert_password_reset_email": "Voulez-vous vraiment envoyer l'e-mail de réinitialisation du mot de passe ?",
      "alert_block_account": "Voulez-vous vraiment bloquer ce compte ?",
      "alert_activate_account": "Voulez-vous vraiment activer ce compte ?",
      "alert_delete_account": "Voulez-vous vraiment supprimer le compte ?",
      "alert_move_message": "Voulez-vous vraiment déplacer ce message ?",
      "alert_move_to_archive": "Voulez-vous vraiment déplacer ce message vers l'archive ?",
      "alert_delete_image": "Voulez-vous vraiment supprimer l'image ?",
      "alert_change_to_professional": "Êtes-vous sûr de vouloir passer au profil professionnel ? Cela limitera certaines fonctionnalités, supprimera des employés",
      "alert_change_to_business": "Voulez-vous vraiment passer au profil professionnel ?",

      "billing": "Facturation",
      "schedule": "Programme",
      "break": "Pause",
      "add_break": "Ajouter une pause",
      "closed": "Fermé",
      "edit_schedule": "Modifier l'horaire",
      "no_time_slots": "Désolé, il n'y a pas de créneaux disponibles à la date sélectionnée, veuillez sélectionner un autre jour",
      "timezone_disclaimer": "Veuillez noter que les créneaux horaires sont basés sur le fuseau horaire actuel de l'entreprise",

      "dashboard" : "Tableau de bord",
      "reset_filters" : "Réinitialiser les filtres"
    }
}
